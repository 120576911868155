<template>
    <ASelect
        style="width:100%;"
        placeholder="Pilih Tahun"
        allow-clear
        :options="state.data"
        v-model:value="valueState"/>
</template>

<script>
import { useVModel } from '@/components/useVModel.js'
import { onMounted, reactive } from 'vue';

export default {
    props: {
        value: {
            type: [Array, Number, String],
            default: () => null,
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const state = reactive({
            data: [],
        })

        onMounted(() => {
            // Membuat array yearOptions dengan format { value, label }
            const currentYear = new Date().getFullYear()
            state.data = Array.from({ length: 10 }, (_, i) => {
                const year = currentYear - i;
                return { value: year, label: String(year) }
            })
        })

        return {
            valueState: useVModel(props, 'value'),
            state,
        }
    },
}
</script>